:root {
  /*Used for page headings, including pop-ups, call to action buttons, selected check boxes,*/
  /*Also used for text on white backgrounds and 20% and 40% backgrounds.*/
  /*Text on this background color should be white.*/
  --blue-100: #133c84;
  /*Used for major headings where appropriate, including the right side of “Shift to Swap” on the Swaps pages, the Accordions on My Profile, and the 4th level of the Area Schedule screen (Area Schedule is an outlier).*/
  /*Text on this background color should be white.*/
  --blue-80: #1966c2;
  --blue-40: #9eb8cf;

  /*Used where lots of color is needed that shouldn’t be overwhelming and for fourth level heading*/
  /*Text on this background color should be blue-100*/
  --blue-20: #cedce7;

  --yellow-100: #e39f19;

  /*Currently used as background color for warning messages in swaps*/
  --yellow-80: #f5ca44;

  --image-blue-100: #061654;
  --image-blue-80: #0e3e8c;
  --image-blue-60: #648cd0;
  --orange: #e69902;

  --pantone-reflex-blue-c: #001489;
  --pantone-129-c: #f3d03e;
  --pantone-285-c: #0072ce;

  --color-header: var(--blue-100);
  --color-header-text: white;
  --color-footer: var(--blue-100);
  --color-footer-text: white;
  --color-text: var(--blue-100);
  --color-background: white;
  --color-button-text: white;
  --color-button-disabled-text: var(--blue-20);
  --color-button-bg: var(--blue-100);
  --color-button-border: var(--blue-100);
  --color-button-hover-bg: var(--blue-80);
  --color-button-hover-border: var(--blue-80);
  --color-button-active-bg: var(--blue-100);
  --color-button-active-border: var(--blue-40);
  --color-buttom-disabled-opacity: 0.5;

  --color-warning: #ffa500;
  --color-critical: #ff0000;
  --color-normal: purple;

  --app-title: var(--blue-100);
  --color-divider: var(--blue-100);

  --vh: 1vh;

  line-height: 1;
}

.ant-pagination {
  padding: 0 0;
}

.ant-table-row {
  line-height: 1;
  padding-top: 0;
}
section.ant-layout {
  background-color: white;
}
main.ant-layout-content {
  padding: 1rem 0 0 0;
  margin: 1rem 0 0 0;
}

td.ant-table-cell button {
  margin: 0;
  padding: 0;
}
/*  ICONS   */
.icon {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 6 free";
  font-weight: 900;
}
.contains::before {
  content: "\f0da";
  font: var(--fa-font-solid);
  padding: 0 0.25rem;
}
.important::before {
  content: "\f024";
  font: var(--fa-font-solid);
}
.click-row::before {
  content: "\f105";
  font: var(--fa-font-solid);
}
.add::before {
  content: "\f0fe";
  font: var(--fa-font-regular);
  padding-right: 0.25rem;
}
.edit::before {
  content: "\f044";
  font: var(--fa-font-regular);
}
.status-good::before {
  content: "\f00c";
  font: var(--fa-font-solid);
  color: green;
  font-size: 0.75rem;
}
.status-bad::before {
  content: "\f00d";
  font: var(--fa-font-solid);
  color: red;
  font-size: 0.75rem;
}
.status-unknown::before {
  content: "\3f";
  font: var(--fa-font-solid);
  color: lightgray;
  font-size: 0.75rem;
}
.comm-failure::before {
  content: "\f111";
  font: var(--fa-font-solid);
  font-size: 0.75rem;
}
.temp-warning::before {
  content: "\f111";
  font: var(--fa-font-solid);
  color: var(--yellow-100);
  font-size: 0.75rem;
}
.temp-critical::before {
  content: "\f111";
  font: var(--fa-font-solid);
  color: red;
  font-size: 0.75rem;
}

.view-title {
  font-size: x-large;
  font-weight: bold;
}
