.audit-timeline {
  margin: 2rem;
}

.audit-timeline h4 {
  margin-bottom: 2rem;
}

.audit-timeline .ant-row {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
}

.audit-timeline .label {
  text-align: right;
}
