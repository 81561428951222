/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 0 0rem 0 0;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #1890ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color: #000;
}
.ant-page-header-back-button:focus-visible,
.ant-page-header-back-button:hover {
  color: #40a9ff;
}
.ant-page-header-back-button:active {
  color: #096dd9;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  white-space: unset;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}
