.editor .ant-drawer-body {
  background-color: white;
  border: 0;
  padding: 0.1rem;
}
.editor .controls {
  border-style: solid;
  border-color: gray;
  background-color: gray;
  margin: 0;
  padding: 0 0 0.5rem 0;
}
.editor .control label {
  color: white;
  font-weight: bold;
}
.editor .control label.ant-radio-button-wrapper {
  color: black;
  font-weight: bold;
}
.editor
  .control
  label.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled {
  color: gray;
  background-color: lightgray;
}

.editor .ant-form-item {
  margin-bottom: 0.75rem;
}
.editor .control.ant-form-item {
  margin-bottom: 0;
}
.editor .title {
  width: "100%";
  justify-content: "center";
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.editor .subtitle {
  font-weight: bold;
}
.editor .buttons {
  width: "100%";
  justify-content: "center";
  margin-top: 2rem;
  margin-bottom: 0;
}
