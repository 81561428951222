.viewer .ant-drawer-body {
  background-color: white;
  border: 0;
  padding: 0.1rem;
}

.viewer .control {
  border-width: 0.2rem 0 0.1rem 0;
  border-style: solid;
  border-color: gray;
  background-color: gray;
  margin-bottom: 0;
}
.viewer .control.last {
  border-width: 0.2rem 0 0.6rem 0;
}
.viewer .control label {
  color: white;
  font-weight: bold;
}
.viewer .control label.ant-radio-button-wrapper {
  color: black;
  font-weight: bold;
}
.viewer .ant-form-item {
  margin-bottom: 0.75rem;
}
.viewer .control.ant-form-item {
  margin-bottom: 0;
}
.viewer .title {
  width: "100%";
  justify-content: "center";
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.viewer .subtitle {
  font-weight: bold;
}
.viewer .buttons {
  width: "100%";
  justify-content: "center";
  margin-top: 2rem;
  margin-bottom: 0;
}

.viewer h1 {
  margin: 2rem;
}

.viewer .label {
  text-align: right;
}
